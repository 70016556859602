import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { postData } from "../../Api/Clientfunctions";
import { toast } from 'react-toastify';  // Assuming you're using react-toastify

const Logindirect = () => {
  const { userId } = useParams();  // Retrieve the dynamic userId from the URL
  const navigate = useNavigate();
  const [response, setResponse] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

 // Auto login on page load
 useEffect(() => {
  const autoLogin = async () => {
    setEmail("newadmin");
    setPassword("123456");
    const res = await postData("/admin/login", { username: "newadmin", password: "123456" });
    if (res.status) {
      navigate("/Dashboard");
      localStorage.setItem("admin", res.token);
      toast.success("Request successfully!");
    } else {
      setTimeout(() => {
        window.location.href = 'https://playkings11.com/admin/manager/index'; // Redirect to google.com
      }, 1000); // 1 second delay before redirect (optional)
      toast.error(res.message ? res.message : "Something went wrong!..");
    }
  };

  autoLogin();
}, [navigate]);

  return (
    <div>
      <h1>User ID: {userId}</h1>
      {response ? <p>Response: {JSON.stringify(response)}</p> : <p>Loading...</p>}
    </div>
  );
};

export default Logindirect;
